const setting = {
  // 项目标题
  title: '拆箱扫码100%赢微信红包',
  // 红包接口 DouMi
  redEnvelopeApiType: 'DouMi',
  // 加载远程图
  loadRemotePic: true,
  // 埋点配置
  tracking: {
    url: process.env.VUE_APP_BASE_API + '/osa/logAgent/save',
    scene: {
      //页面进入前
      before: {
        // 路由名称
        turntable: {
          userVisit: 6
        },
        redEnvelopes: {
          userVisit: 17
        }
      },
      //页面进入后
      after: {},
      //返回页面
      back: {},
      //页面所有接口请求后埋点
      nextRequest: {
        turntable: {
          userVisit: 2
        }
      }
    }
  }
}

export default setting

if (process.env.NODE_ENV != 'production') {
  console.log(
    '%c开发环境输出以下配置信息，避免开发时遗漏',
    'color: red; font-size: 20px;'
  )
  console.table([
    {
      key: 'title',
      value: setting.title,
      description: '页面标题'
    },
    {
      key: 'redEnvelopeApiType',
      value: setting.redEnvelopeApiType,
      description: '红包接口类型'
    },
    {
      key: 'loadRemotePic',
      value: setting.loadRemotePic,
      description: '加载远程图'
    },
    {
      key: 'VUE_APP_BASE_API',
      value: process.env.VUE_APP_BASE_API,
      description: '接口地址'
    },
    {
      key: 'VUE_APP_QUESTION_URL',
      value: process.env.VUE_APP_QUESTION_URL,
      description: '问题咨询地址'
    },
    {
      key: 'VUE_APP_QUESTION_TITLE',
      value: process.env.VUE_APP_QUESTION_TITLE,
      description: '问题咨询CODE'
    },
    {
      key: 'VUE_APP_ROUTER_BASE',
      value: process.env.VUE_APP_ROUTER_BASE,
      description: '路由及打包目录'
    },
    {
      key: 'VUE_APP_BASE_PIC',
      value: process.env.VUE_APP_BASE_PIC,
      description: 'OSS地址'
    },
    {
      key: 'VUE_APP_PRIVACYSTATEMENT_URL',
      value: process.env.VUE_APP_PRIVACYSTATEMENT_URL,
      description: '隐私声明地址'
    }
  ])
  console.log('----------------------------')
}
